import NewsletterFormCompact from '@/components/ui/organisms/newsletter/newsletter-form-compact'

const LeadFormNewsletterCompact = ({ formSource, redirectPage }) => {
	return <NewsletterFormCompact source={formSource} redirect={!!redirectPage} redirectTo={redirectPage} />
}

LeadFormNewsletterCompact.defaultProps = {}

LeadFormNewsletterCompact.propTypes = {}

export default LeadFormNewsletterCompact
