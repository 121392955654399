import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import Newsletter from '@bluheadless/ui/src/organisms/newsletter/newsletter'
import { cx } from '@emotion/css'
import { object, string } from 'prop-types'
import { FormWrapper, Root } from './newsletter-form-compact.styled'

const NewsletterFormCompact = ({
	variant,
	action,
	inputProps,
	buttonProps,
	iconButtonProps,
	className,
	checkboxProps,
	source,
	redirect,
	redirectTo,
	...props
}) => {
	const {
		newsletter: { enabled: newsletterEnabled },
	} = useConfig()

	return newsletterEnabled ? (
		<Root className={cx('NewsletterFormCompact-root', className)} {...props}>
			<FormWrapper>
				<Newsletter
					source={source}
					redirect={redirect}
					redirectTo={redirectTo}
					variant={variant}
					action={action}
					checkboxProps={checkboxProps}
					inputProps={inputProps}
					buttonProps={buttonProps}
					iconButtonProps={iconButtonProps}
					forceVariant={true}
				/>
			</FormWrapper>
		</Root>
	) : null
}

NewsletterFormCompact.defaultProps = {
	variant: 'standard',
	action: 'dialog',
	buttonProps: {
		color: 'secondary',
	},
}

NewsletterFormCompact.propTypes = {
	/**
	 *  Defines action, can be 'standard' to send the request directly  or 'dialog'
	 *  to fill out the complete form
	 */
	action: string.isRequired,
	/**
	 * Defines the properties of the newsletter input field
	 */
	inputProps: object,
	/**
	 * Defines the properties of the privacy checkbox
	 */
	checkboxProps: object,
	/**
	 * Defines the properties of the subscribe button
	 */
	buttonProps: object,
}

export default NewsletterFormCompact
