import {
	MUIGridStyled as GridNwlFormMini,
	SubmitButtonStyled as SubmitBtnNwlFormMini,
} from '@bluheadless/ui/src/organisms/newsletter/newsletter-form-mini.styled'
import { FormStyled } from '@bluheadless/ui/src/organisms/newsletter/newsletter.styled'
import { styled } from '@mui/system'

export const Root = styled('div')``

export const FormWrapper = styled('div')`
	${FormStyled} {
	}
	${GridNwlFormMini} {
		flex-wrap: wrap;
		justify-content: center;
		fieldset {
			border-top: none;
			border-left: none;
			border-right: none;
			border-radius: 0;
		}
	}
	${SubmitBtnNwlFormMini} {
		margin-bottom: ${({ theme }) => theme.spacing(2)};
	}
`
